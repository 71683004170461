import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.editlist = this.application.
      getControllerForElementAndIdentifier(this.element, 'editlist') ;
  }

  change(e) {
    e.target.form.dispatchEvent(new CustomEvent('submit', {bubbles: true})) ;
  }

  createAtt(data) {
    let {id, html} = data.blobs[0] ;
    this.element.classList.add('has-blob') ;
    this.element.querySelector('input[name$="[blob_id]"]').value = id ;
    this.element.querySelector('input[name$="[_destroy]"]').value = 0 ;
    this.element.querySelector('[data-role="blob"]').innerHTML = html ;
  }

  destroyAtt(e) {
    e.preventDefault() ;
    this.element.classList.remove('has-blob') ;
    this.element.querySelector('input[name$="[blob_id]"]').value = null ;
    this.element.querySelector('input[name$="[_destroy]"]').value = 1 ;
    this.element.querySelector('[data-role="blob"]').innerHTML = '' ;
  }

  createAtts(data) {
    data.blobs.forEach(({id, html}) => {
      this.editlist.addMore(null, function (node) {
        node.classList.remove('is-hidden') ;
        node.querySelector('input[name$="[blob_id]"]').value = id ;
        node.querySelector('[data-role="blob"]').innerHTML = html ;
      }) ;
    })
  }

  upload(input) {
    let fd = new FormData() ;

    [...input.files].forEach(f => fd.append('blob[file][]', f)) ;

    return fetch('/blobs', {
      method: 'POST',
      body: fd,
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Accept': 'application/json'
      }
    }).then(resp => resp.json())
  }

  uploadAtts(e) {
    let input = e.target ;
    this.upload(input).then(data => this.createAtts(data)) ;
    input.value = null ;
  }

  uploadAtt(e) {
    let input = e.target ;
    this.upload(input).then(data => this.createAtt(data)) ;
    input.value = null ;
  }
}
