import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["iframe"]

  tabshown(e) {
    const iframe = this.iframeTarget,
      src = iframe.closest('.control').querySelector('#body-input textarea') ;

    iframe.contentWindow.
      postMessage({type: 'reload', content: src.value}, '*') ;
  }
}

