import { Controller } from "stimulus"
import {Sortable} from 'sortablejs'

export default class extends Controller {
  static targets = ["template", "sortable"]

  connect() {
    if (this.hasSortableTarget)
      Sortable.create(this.sortableTarget, {
	handle: 'label, img, input[type=number]',
        onUpdate: (e) => this.sortableUpdate()
      }) ;
  }

  sortableUpdate() {
    this.sortableTarget.querySelectorAll('input[name$="[prio]"]').
      forEach((input, i) => input.value = i + 1) ;
  }

  addMore(e, callback) {
    // console.log('data', data) ;
    if (e) e.preventDefault() ;
    let template = this.templateTarget ;
    let node = template.cloneNode(true) ;

    delete node.dataset.editlistTarget ;

    if (!callback)
      node.classList.remove('is-hidden') ;
    else
      callback(node) ;

    template.querySelectorAll('[name]').forEach((input, i) => {
      input.name = input.name.replace(/\[(\d+)\]/,
        (_, num) => ('[' + (parseInt(num) + 1) + ']')) ;
      input.id = input.id.replace(/_(\d+)_/,
        (_, num) => ('_' + (parseInt(num) + 1) + '_')) ;
    }) ;

    template.parentElement.insertBefore(node, template);
    this.sortableUpdate() ;
  }
}
