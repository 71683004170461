import BulmaTagsInput from '@creativebulma/bulma-tagsinput';
import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.source = fetch('/topics.json').
      then(resp => resp.json()).
      then(data => data.map(t => t.title)) ;
  }

  connect() {
    if (this.element.name) {
      this.source.then(data => new BulmaTagsInput(this.element, {
        source: data, freeInput: false, removeable: true
      })) ;
    }
  }
}

