import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.timer = window.setInterval(
      () => { if (!this.element.matches(':hover')) this.dismiss(false) },
      5000) ;
  }

  dismiss(e) {
    if (this.timer) window.clearInterval(this.timer) ;
    if (e) return this.element.remove() ;

    this.element.addEventListener('transitionend', e => e.target.remove()) ;
    this.element.style.transition = 'opacity 2s' ;
    this.element.style.opacity = '0' ;
  }
}
