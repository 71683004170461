import { Controller } from "stimulus"

export default class extends Controller {
  change(e) {
    if (e.target.checked)
      this.element.classList.add('is-destroyed') ;
    else
      this.element.classList.remove('is-destroyed') ;
  }
}
