import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  connect() {
    this.tabTargets.forEach((a, i) => {
      a.addEventListener('click', e => this.clickTab(e)) ;
    }) ;
  }

  clickTab(e) {
    e.preventDefault() ;
    const node = e.target, pid = node.hash.slice(1) ;
    this.tabTargets.forEach(a => {
      if (a == node)
        a.parentNode.classList.add('is-active') ;
      else
        a.parentNode.classList.remove('is-active') ;
    }) ;

    this.panelTargets.forEach(p => {
      if (p.id == pid) {
        if (p.classList.contains('is-hidden')) {
          p.classList.remove('is-hidden') ;
          p.dispatchEvent(new Event('tabshown')) ;
        }
      } else
        p.classList.add('is-hidden') ;
    }) ;
  }

}

