import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "charcount", "charcounter" ] ;
  static values = { limit: Number } ;

  connect() {
    var counter = document.createElement('span') ;
    counter.className = 'help' ;
    counter.setAttribute('data-validations-target', 'charcounter') ;
    this.charcountTarget.after(counter) ;
    this.update() ;
  } ;

  update() {
    var length = this.charcountTarget.value.length ;
    var counter = this.charcounterTarget ;
    counter.textContent = length + '/' + this.limitValue ;
    if (length > this.limitValue) {
      counter.classList.remove('is-success') ;
      counter.classList.add('is-danger') ;
    } else {
      counter.classList.remove('is-danger') ;
      counter.classList.add('is-success') ;
    }
  }
}
