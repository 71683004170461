import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "link", "menu" ]

  connect() {
    this.linkTargets.forEach((a, i) => {
      var h = a.href ;
      if (h[h.length - 1] === '/') h = h.substr(0, h.length - 1) ;

      if (window.location.href.lastIndexOf(h, 0) === 0)
        a.classList.add('is-active') ;
      else
        a.classList.remove('is-active') ;
    }) ;

    if (window.location.pathname == '/')
      this.linkTargets[0].classList.add('is-active') ;
  }

  toggle(ev) {
    ev.currentTarget.classList.toggle('is-active') ;
    this.menuTarget.classList.toggle('is-active') ;
  }
}
